import { PlainButton } from "@ui/components/buttons/PlainButton";
import { CheckmarkIcon } from "@ui/components/icons/CheckmarkIcon";
import styles from "@ui/components/buttons/css/CheckboxButton.module.css";
import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  isActive: boolean;
  onClick: () => void;
};

export function CheckboxButton({ isActive, onClick }: Props): JSX.Element {
  const className = isActive ? styles.active : styles.inactive;
  const children = isActive ? (
    <CheckmarkIcon colorValue={ColorValue.White} />
  ) : null;

  return (
    <PlainButton
      className={className}
      onClick={onClick}
      transparentBg={false}
      type="button"
    >
      {children}
    </PlainButton>
  );
}
