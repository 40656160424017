import { PageWithHeaderAndFooterProps as Props } from "@ui/components/containers/PageWithHeaderAndFooter";
import { Footer } from "@web/components/footers/Footer";
import { Header } from "@web/components/headers/Header";
import { PageWithHeaderAndFooter as PageWithHeaderAndFooterUi } from "ui";

export function PageWithHeaderAndFooter({
  children,
  hasBottomTabs = false,
}: Omit<Props, "header" | "footer">): JSX.Element {
  return (
    <PageWithHeaderAndFooterUi
      header={<Header />}
      footer={<Footer />}
      hasBottomTabs={hasBottomTabs}
    >
      {children}
    </PageWithHeaderAndFooterUi>
  );
}
