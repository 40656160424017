import { MediaType } from "@ui/types/enums/MediaType";

export const HUMAN_READABLE_MEDIA_TYPE: Record<MediaType, string> = {
  [MediaType.ImageGif]: "gif",
  [MediaType.ImageJpg]: "jpeg",
  [MediaType.ImageJpeg]: "jpeg",
  [MediaType.ImagePng]: "png",
  [MediaType.ModelGltfBinary]: "glb",
  [MediaType.VideoMp4]: "mp4",
  [MediaType.VideoQuicktime]: "mov",
};
