import { BodyText } from "@ui/components/text/BodyText";
import { Maybe } from "shared";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { FontClass } from "@ui/types/enums/FontClass";

type Props = {
  children: any;
  fontClass: FontClass;
  marginTop?: number;
  textAlign?: "center" | "left";
};

export function ErrorText({
  children,
  fontClass,
  marginTop = 24,
  textAlign = "center",
}: Props): Maybe<JSX.Element> {
  if (children == null) {
    return null;
  }

  return (
    <BodyText
      colorClass={ColorClass.Red}
      fontClass={fontClass}
      style={{ marginTop }}
      textAlign={textAlign}
    >
      {children}
    </BodyText>
  );
}
