import { CSSProperties } from "react";

type Props = {
  alignItems?: CSSProperties["alignItems"];
  children: any;
  className?: string;
  gap?: number;
  flexDirection?: CSSProperties["flexDirection"];
  justifyContent?: CSSProperties["justifyContent"];
};

export function FlexBox({
  alignItems,
  children,
  className,
  flexDirection = "row",
  gap = 0,
  justifyContent,
}: Props) {
  return (
    <div
      className={className}
      style={{
        alignItems,
        display: "flex",
        flexDirection,
        gap,
        justifyContent,
      }}
    >
      {children}
    </div>
  );
}
