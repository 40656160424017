/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableName {
  BackgroundOverlay = "--color-backgroundOverlay",
  Beige = "--color-beige",
  CardBackground = "--color-cardBackground",
  Dimmer = "--color-dimmer",
  FloatingElement = "--color-floatingElement",
  Ghost = "--color-ghost",
  MenuPopup = "--color-menuPopup",
  Primary = "--color-primary",
  Red = "--color-red",
  RedHover = "--color-redHover",
  Secondary = "--color-secondary",
  Shader = "--color-shader",
  SkeletonBase = "--color-skeletonBase",
  SkeletonHighlight = "--color-skeletonHighlight",
  Tertiary = "--color-tertiary",
  WebsiteBackground = "--color-websiteBackground",
  White = "--color-white",
}
