/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorVariableDarkModeName } from "@ui/types/enums/ColorVariableDarkModeName";
import { ColorVariableName } from "@ui/types/enums/ColorVariableName";

export const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_DARK_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableDarkModeName
> = {
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableDarkModeName.BackgroundOverlay,
  [ColorVariableName.Beige]: ColorVariableDarkModeName.Beige,
  [ColorVariableName.CardBackground]: ColorVariableDarkModeName.CardBackground,
  [ColorVariableName.Dimmer]: ColorVariableDarkModeName.Dimmer,
  [ColorVariableName.FloatingElement]:
    ColorVariableDarkModeName.FloatingElement,
  [ColorVariableName.Ghost]: ColorVariableDarkModeName.Ghost,
  [ColorVariableName.MenuPopup]: ColorVariableDarkModeName.MenuPopup,
  [ColorVariableName.Primary]: ColorVariableDarkModeName.Primary,
  [ColorVariableName.Red]: ColorVariableDarkModeName.Red,
  [ColorVariableName.RedHover]: ColorVariableDarkModeName.RedHover,
  [ColorVariableName.Secondary]: ColorVariableDarkModeName.Secondary,
  [ColorVariableName.Shader]: ColorVariableDarkModeName.Shader,
  [ColorVariableName.SkeletonBase]: ColorVariableDarkModeName.SkeletonBase,
  [ColorVariableName.SkeletonHighlight]:
    ColorVariableDarkModeName.SkeletonHighlight,
  [ColorVariableName.Tertiary]: ColorVariableDarkModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableDarkModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableDarkModeName.White,
};
