import {
  ResponsiveContainer,
  TextButton,
  TextButtonTheme,
  FontClass,
  joinClasses,
} from "ui";
import styles from "@web/components/headers/css/Header.module.css";

export function Header(): JSX.Element {
  return (
    <div>
      <ResponsiveContainer className={styles.header}>
        <div className={styles.containerInner}>
          <TextButton
            buttonThemeOrColorClass={TextButtonTheme.Primary}
            className={styles.navItem}
            display="block"
            fontClass={FontClass.NavItem}
            href="/"
            textTransform="uppercase"
            type="link_internal"
          >
            Camera List
          </TextButton>
          <TextButton
            buttonThemeOrColorClass={TextButtonTheme.Primary}
            className={joinClasses(styles.navItem, styles.navItemWithBorders)}
            display="block"
            fontClass={FontClass.NavItem}
            href="/compare"
            textTransform="uppercase"
            type="link_internal"
          >
            Compare Cameras
          </TextButton>
          <TextButton
            buttonThemeOrColorClass={TextButtonTheme.Primary}
            className={styles.navItem}
            display="block"
            fontClass={FontClass.NavItem}
            href="/about"
            textTransform="uppercase"
            type="link_internal"
          >
            About
          </TextButton>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
