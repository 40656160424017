import styles from "@web/components/footers/css/Footer.module.css";
import {
  Body1,
  CameraName,
  ColorClass,
  FontClass,
  ResponsiveContainer,
  TextButton,
  TextButtonTheme,
} from "ui";

export function Footer(): JSX.Element {
  return (
    <div>
      <ResponsiveContainer>
        <div className={styles.footer}>
          <CameraName colorClass={ColorClass.Primary}>Analog Atlas</CameraName>
          <Body1 colorClass={ColorClass.Primary}>
            Made by{" "}
            <TextButton
              buttonThemeOrColorClass={TextButtonTheme.Primary}
              display="inline"
              fontClass={FontClass.Body1}
              href="https://www.mattlim.me/"
              type="link_external"
            >
              pencilflip
            </TextButton>
          </Body1>
          <Body1 colorClass={ColorClass.Primary}>
            Copyright {new Date().getFullYear()}
          </Body1>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
