import { AspectRatioContainer } from "@ui/components/containers/AspectRatioContainer";

type Props = {
  children: any;
  className?: string;
  classNameContainerInner?: string;
};

export function SquareContainer({
  children,
  className,
  classNameContainerInner,
}: Props): JSX.Element {
  return (
    <AspectRatioContainer
      className={className}
      classNameContainerInner={classNameContainerInner}
      height={1}
      width={1}
    >
      {children}
    </AspectRatioContainer>
  );
}
