/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorValue {
  BackgroundOverlay = "var(--color-backgroundOverlay)",
  Beige = "var(--color-beige)",
  CardBackground = "var(--color-cardBackground)",
  Dimmer = "var(--color-dimmer)",
  FloatingElement = "var(--color-floatingElement)",
  Ghost = "var(--color-ghost)",
  MenuPopup = "var(--color-menuPopup)",
  Primary = "var(--color-primary)",
  Red = "var(--color-red)",
  RedHover = "var(--color-redHover)",
  Secondary = "var(--color-secondary)",
  Shader = "var(--color-shader)",
  SkeletonBase = "var(--color-skeletonBase)",
  SkeletonHighlight = "var(--color-skeletonHighlight)",
  Tertiary = "var(--color-tertiary)",
  WebsiteBackground = "var(--color-websiteBackground)",
  White = "var(--color-white)",
}
