import { notify } from "@ui/components/toast/notify";
import { UNEXPECTED_ERROR_MESSAGE } from "@ui/constants/UnexpectedErrorMessage";

export function notifyError(description?: string, message?: string) {
  notify({
    description: description ?? UNEXPECTED_ERROR_MESSAGE,
    message: message ?? UNEXPECTED_ERROR_MESSAGE,
    type: "error",
  });
}
