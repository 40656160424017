import styles from "@ui/components/containers/css/AspectRatioContainer.module.css";
import { joinClasses } from "@ui/utils/joinClasses";

type Props = {
  children: any;
  className?: string;
  classNameContainerInner?: string;
  height: number;
  width: number;
};

export function AspectRatioContainer({
  width,
  height,
  children,
  className,
  classNameContainerInner,
}: Props): JSX.Element {
  const aspectRatio = height / width;
  return (
    <div className={styles.containerOuter}>
      <div
        style={{ paddingBottom: `calc(${aspectRatio} * 100%)` }}
        className={joinClasses(styles.container, className)}
      >
        <div
          className={joinClasses(
            styles.containerInner,
            classNameContainerInner
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
