import { PageWithBottomTabs } from "@ui/components/bottom-tabs/PageWithBottomTabs";
import styles from "@ui/components/containers/css/PageWithHeaderAndFooter.module.css";
import { useIsBottomTabsWidth } from "@ui/hooks/dimensions/useIsBottomTabsWidth";

export type PageWithHeaderAndFooterProps = {
  children: any;
  footer: JSX.Element;
  hasBottomTabs?: boolean;
  header: JSX.Element;
};

export function PageWithHeaderAndFooter({
  children,
  footer,
  hasBottomTabs = false,
  header,
}: PageWithHeaderAndFooterProps): JSX.Element {
  const isBottomTabsWidth = useIsBottomTabsWidth();

  return (
    <PageWithBottomTabs hideBottomTabs={!hasBottomTabs}>
      <div className={styles.container}>
        {header}
        <div className={styles.body}>{children}</div>
        {isBottomTabsWidth && hasBottomTabs ? null : footer}
      </div>
    </PageWithBottomTabs>
  );
}
