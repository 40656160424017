import { HideIfEmpty } from "@ui/components/containers/HideIfEmpty";
import styles from "@ui/components/inputs/css/InputWithLabels.module.css";
import { Body1 } from "@ui/components/text/Body1";
import { Body2 } from "@ui/components/text/Body2";
import { ColorClass } from "@ui/types/enums/ColorClass";

type Props = {
  // The input
  children: any;
  hint?: string | JSX.Element;
  label?: string | JSX.Element;
  subLabel?: string | JSX.Element;
};

export function InputWithLabels({ children, hint, label, subLabel }: Props) {
  const hintElem =
    typeof hint === "string" ? (
      <Body2 colorClass={ColorClass.Secondary}>{hint}</Body2>
    ) : (
      hint
    );
  const labelElem =
    typeof label === "string" ? (
      <Body1 colorClass={ColorClass.Primary}>{label}</Body1>
    ) : (
      label
    );
  const subLabelElem =
    typeof subLabel === "string" ? (
      <Body2 colorClass={ColorClass.Secondary}>{subLabel}</Body2>
    ) : (
      subLabel
    );

  return (
    <div className={styles.container}>
      <HideIfEmpty className={styles.labels}>
        {labelElem}
        {subLabelElem}
      </HideIfEmpty>
      {children}
      {hintElem}
    </div>
  );
}
