import { ColorValue } from "@ui/types/enums/ColorValue";

type Props = {
  colorValue: ColorValue;
};

export function TriangleDown({ colorValue }: Props): JSX.Element {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 3H12L6 11L0 3Z" fill={colorValue} />
    </svg>
  );
}
