import { CSSProperties } from "react";
import { ColorValue } from "@ui/types/enums/ColorValue";
import { joinClasses } from "@ui/utils/joinClasses";
import styles from "@ui/components/loading/css/LoadingSpinner.module.css";
import { FontSize } from "@ui/types/enums/FontSize";
import { enumValueToKey, MaybeUndef } from "shared";
import { FontClass } from "@ui/types/enums/FontClass";

const COLOR_MAP = {
  [ColorValue.Primary]: styles.primary,
  [ColorValue.Secondary]: styles.secondary,
  [ColorValue.White]: styles.white,
};

type Props = {
  className?: string;
  colorValue: keyof typeof COLOR_MAP;
  // For when you want the loading spinner to have the same height and width
  // as a font's line height.
  fontClass?: MaybeUndef<FontClass>;
  size?: number;
  style?: CSSProperties;
};

export function LoadingSpinner({
  className,
  colorValue,
  fontClass,
  size = 40,
  style = {},
}: Props): JSX.Element {
  const colorClassName = COLOR_MAP[colorValue];
  const fontClassKey =
    fontClass == null ? null : enumValueToKey(FontClass, fontClass);
  const fontSize = fontClassKey != null ? FontSize[fontClassKey] : null;
  const styleInner = fontSize != null ? {} : { height: size, width: size };

  return (
    <div
      className={joinClasses(
        styles.ldsDualRing,
        colorClassName,
        className,
        fontSize
      )}
      style={{ ...style, ...styleInner }}
    />
  );
}
