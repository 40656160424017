import { useDimensions } from "@ui/hooks/dimensions/useDimensions";
import { BOTTOM_TABS_WIDTH } from "@ui/hooks/dimensions/useIsBottomTabsWidth";
import { Dimensions } from "@ui/types/Dimensions";
import { useEffect, useState } from "react";

function dummyFunctionForServer() {
  return {
    height: 0,
    // So bottom tabs are not shown on hydration
    width: BOTTOM_TABS_WIDTH + 1,
  };
}

// Returns dimensions of the viewport which includes things like
// scrollbar width
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    height,
    width,
  };
}

export function useWindowDimensions(): {
  height: number;
  width: number;
} {
  // See https://nextjs.org/docs/messages/react-hydration-error for why we do this
  //
  // See https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1 for why
  // the extra anon function is needed
  const [fn, setFn] = useState<() => Dimensions>(() => dummyFunctionForServer);
  useEffect(() => {
    setFn(() => getWindowDimensions);
  }, []);

  return useDimensions(fn);
}
