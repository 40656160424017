/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorClass } from "@ui/types/enums/ColorClass";
import { ColorValue } from "@ui/types/enums/ColorValue";

export const COLOR_CLASS_TO_COLOR_VALUE: Record<ColorClass, ColorValue> = {
  [ColorClass.BackgroundOverlay]: ColorValue.BackgroundOverlay,
  [ColorClass.Beige]: ColorValue.Beige,
  [ColorClass.CardBackground]: ColorValue.CardBackground,
  [ColorClass.Dimmer]: ColorValue.Dimmer,
  [ColorClass.FloatingElement]: ColorValue.FloatingElement,
  [ColorClass.Ghost]: ColorValue.Ghost,
  [ColorClass.MenuPopup]: ColorValue.MenuPopup,
  [ColorClass.Primary]: ColorValue.Primary,
  [ColorClass.Red]: ColorValue.Red,
  [ColorClass.RedHover]: ColorValue.RedHover,
  [ColorClass.Secondary]: ColorValue.Secondary,
  [ColorClass.Shader]: ColorValue.Shader,
  [ColorClass.SkeletonBase]: ColorValue.SkeletonBase,
  [ColorClass.SkeletonHighlight]: ColorValue.SkeletonHighlight,
  [ColorClass.Tertiary]: ColorValue.Tertiary,
  [ColorClass.WebsiteBackground]: ColorValue.WebsiteBackground,
  [ColorClass.White]: ColorValue.White,
};
