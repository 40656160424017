/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genFonts.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum FontSize {
  Body1 = "body1Size",
  Body1Bold = "body1Size",
  Body1Medium = "body1Size",
  Body1SemiBold = "body1Size",
  Body2 = "body2Size",
  Body2Bold = "body2Size",
  Body2Medium = "body2Size",
  Body2SemiBold = "body2Size",
  Body3 = "body3Size",
  Body3Bold = "body3Size",
  Body3Medium = "body3Size",
  Body3SemiBold = "body3Size",
  CameraName = "cameraNameSize",
  Header1 = "header1Size",
  Header2 = "header2Size",
  Header3 = "header3Size",
  NavItem = "navItemSize",
  Subheader = "subheaderSize",
  TinyLabel = "tinyLabelSize",
}
